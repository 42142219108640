var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-direction": "column"
    }
  }, [_c('h3', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "font-weight": "500",
      "font-size": "12px",
      "color": "#333333"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "d": "block",
      "mt": "7px",
      "mb": "17px"
    }
  }), _vm.items.length > 0 ? _c('c-list', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        gap: [null, '10px 20px']
      },
      expression: "{\n      gap: [null, '10px 20px'],\n    }"
    }],
    attrs: {
      "d": [null, 'flex'],
      "flex-wrap": [null, 'wrap']
    }
  }, _vm._l(_vm.items, function (item) {
    return _c('c-list-item', {
      key: item.id,
      attrs: {
        "w": ['100%', '386px'],
        "h": ['64px', '112px'],
        "bg": "white",
        "border": "1px solid #F2F2F2",
        "box-shadow": "0px 5px 30px rgba(0, 0, 0, 0.05)",
        "border-radius": "6px",
        "d": "flex",
        "align-items": "center",
        "justify-content": "space-between",
        "margin-bottom": "6px",
        "px": "22px"
      }
    }, [_c('c-flex', {
      attrs: {
        "flex-dir": ['row', 'column'],
        "align-items": ['center', 'flex-start'],
        "justify-content": ['space-between', null],
        "flex": "1"
      }
    }, [_c('c-text', {
      attrs: {
        "font-weight": "600",
        "font-size": "14px",
        "color": "#111"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), item.isEditable ? _c('c-text', {
      attrs: {
        "mt": [null, '10px'],
        "color": "primary.400",
        "font-weight": "500",
        "font-size": "12px"
      }
    }, [_vm._v(" Edit ")]) : _vm._e()], 1), _c('c-flex', {
      attrs: {
        "align-items": "center",
        "cursor": _vm.$listeners.click ? 'pointer' : ''
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('click', item);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "d": ['block', 'none'],
        "src": require('@/assets/icon-chevron-right.svg'),
        "alt": "icon arrow right"
      }
    }), _c('c-image', {
      attrs: {
        "d": ['none', 'block'],
        "src": require('@/assets/icon-chevron-right-grey.svg'),
        "alt": "icon arrow right"
      }
    })], 1)], 1);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }